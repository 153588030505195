import * as types from "../../../constants";

const initialState = { loading: false, selectedProject: {}, projectList: [] };

const reducer = (state = initialState, action) => {
  let filteredProjectList = [];

  switch (action.type) {
    case types.PROJECT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.PROJECT_LOADING_DISABLE:
      return {
        ...state,
        loading: false,
      };
    case types.RETRIEVE_PROJECT_LIST:
      return {
        ...state,
        projectList: [...action.payload],
        loading: false,
      };
    case types.RETRIEVE_PROJECT_DETAILS:
      return {
        ...state,
        selectedProject: action.payload,
        loading: false,
      };
    case types.RESET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: {},
      };

    case types.DELETE_PROJECT:
      filteredProjectList = state.projectList.filter(
        (project) => project.projectId !== action.payload
      );

      return {
        ...state,
        projectList: [...filteredProjectList],
        loading: false,
      };
    case types.UPDATE_PROJECT:
      filteredProjectList = state.projectList.filter(
        (project) => project.projectId !== action.payload.projectId
      );

      return {
        ...state,
        projectList: [...filteredProjectList, action.payload],
        selectedProject: action.payload,
        loading: false,
      };
    case types.RESET_PROJECT_LIST:
      return {
        ...state,
        selectedProject: {},
        projectList: [],
      };

    default:
      return state;
  }
};

export default reducer;
