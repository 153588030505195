import * as types from "../../../constants";

const initialState = { loading: false, projectDetails: {} };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SU_PROJECT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SU_PROJECT_LOADING_DISABLE:
      return {
        ...state,
        loading: false,
      };
    case types.SU_RETRIEVE_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: { ...action.payload },
        loading: false,
      };
    case types.RESET_SELECTED_PROJECT:
      return {
        ...state,
        projectDetails: {},
      };

    // case types.DELETE_PROJECT:
    //   filteredProjectList = state.projectList.filter(
    //     (project) => project.projectId !== action.payload
    //   );

    //   return {
    //     ...state,
    //     projectList: [...filteredProjectList],
    //     loading: false,
    //   };
    // case types.UPDATE_PROJECT:
    //   filteredProjectList = state.projectList.filter(
    //     (project) => project.projectId !== action.payload.projectId
    //   );

    //   return {
    //     ...state,
    //     projectList: [...filteredProjectList, action.payload],
    //     selectedProject: action.payload,
    //     loading: false,
    //   };
    // case types.RESET_PROJECT_LIST:
    //   return {
    //     ...state,
    //     selectedProject: {},
    //     projectList: [],
    //   };

    default:
      return state;
  }
};

export default reducer;
