import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";

const Contact = () => {
  return (
    <Card mb={6}>
      <CardContent>
        <Grid container direction="column" spacing={6}>
          <Grid item>
            <Typography variant="h5">Contact Us</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ fontSize: "14px" }}>
              Please email us at support@ecosense.io or fill out the support
              form below. <br />
              Tell us about the challenge you’re experiencing and we’ll get back
              to you within 24 hours.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              href="https://ecosense.io/pages/contact-us"
              variant="contained"
              color="primary"
              mt={3}
            >
              Contact support
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Contact;
