import styled from "styled-components/macro";
import Button from "@material-ui/core/Button";

export const StyledButton = styled(Button)`
  background: ${(props) => props.theme.button.color};

  &:disabled {
    background: ${(props) => props.theme.button.disabled};
  }

  &:hover {
    background: ${(props) => props.theme.button.hover};
  }
  &:active {
    background: ${(props) => props.theme.button.active};
  }

  span {
    color: white;
  }
`;

const EcoButton = (props) => {
  return <StyledButton {...props} />;
};

export default EcoButton;
