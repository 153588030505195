import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import { resendSignUp, verifyCode } from "../../models/actions/authActions";
import bg from "../../assets/img/bg-half.png";
import EQBrandLogo from "../../assets/img/ecosense-brand-logo-negative-white.png";
import EQLogo from "../../assets/img/EQ200_Logo_wh.png";

import {
  Button,
  CircularProgress,
  Paper,
  TextField as MuiTextField,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import "../../assets/css/common.css";
import "../../assets/css/auth.css";
import InputBase from "@material-ui/core/InputBase";
import { useSnackbar } from "notistack";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("xs")} {
    padding-top: ${(props) => props.theme.spacing(10)}px;
    padding-bottom: ${(props) => props.theme.spacing(10)}px;
    padding-left: ${(props) => props.theme.spacing(15)}px;
    padding-right: ${(props) => props.theme.spacing(15)}px;
  }
`;

const validate = (values) => {
  const errors = {};

  if (!values.passcode) {
    errors.passcode = "Required";
  } else if (values.passcode.length !== 6 || isNaN(values.passcode)) {
    errors.passcode = "Passcode must be 6 digits";
  }

  return errors;
};

function VerifyPasscode() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(1500));

  const resendVerificationCode = async () => {
    const email = location.state.email;
    try {
      dispatch(resendSignUp(email));
      history.push({
        pathname: "/auth/verify-code",
        state: { email: email },
      });
    } catch (error) {
      // let message = error.message || "Something went wrong";
      // setStatus({ success: false });
      // setErrors({ submit: message });
      // setSubmitting(false);
    }
  };

  return (
    <Grid
      container
      direction="row"
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
      }}
    >
      {matches && (
        <Grid
          xs={6}
          item
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: "#3DB5E6",
            width: "100vw",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid item style={{ marginTop: "auto" }}>
            <img
              style={{
                width: "100%",
                height: "80px",
                objectFit: "contain",
              }}
              src={EQLogo}
              alt="ecosense logo"
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h1"
              style={{
                marginTop: 26,
                textAlign: "center",
                color: "white",
                fontFamily: "avenir",
                fontWeight: "500",
                width: "100%",
              }}
            >
              Radon Testing with Ecosense Solution Fast and Accurately
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: "auto" }}>
            <img
              style={{
                width: "100%",
                height: "200px",
                objectFit: "contain",
              }}
              src={EQBrandLogo}
              alt="ecosense brand logo"
            />
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs={matches ? 6 : 12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Wrapper style={{ width: "680px" }}>
          <Helmet title="Verify Passcode" />
          <div>
            <div className="s20"></div>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              Verify your email address
            </Typography>
            <div className="s20"></div>
            <Typography component="h2" variant="body1" align="center">
              We have sent verification code to <br />
              <b>{location.state.email}</b>
            </Typography>
            <Typography component="h2" variant="body1" align="center">
              Check your spam or just folder
              <br /> if you don't see it
            </Typography>
          </div>

          <Formik
            initialValues={{
              passcode: "",
              email: location.state.email,
              submit: false,
            }}
            validate={validate}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                dispatch(
                  verifyCode({
                    email: location.state.email,
                    passcode: values.passcode.trim(),
                  })
                );
                enqueueSnackbar("Sign-up success. Please log in.", {
                  variant: "success",
                });
                history.push("/auth/sign-in");
              } catch (error) {
                let message;
                if (
                  error.message ===
                  "Custom auth lambda trigger is not configured for the user pool."
                ) {
                  //TODO: Need to add new page
                  history.push("/auth/confirm-sign-up");
                } else {
                  message = error.message || "Something went wrong";
                }

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <>
                    <div className="s10"></div>
                    <Alert mt={2} mb={1} severity="warning">
                      {errors.submit}
                    </Alert>
                  </>
                )}
                <InputBase disabled inputProps={{ "aria-label": "naked" }} />
                <TextField
                  type="text"
                  name="passcode"
                  label="Verification code"
                  value={values.passcode}
                  error={Boolean(touched.passcode && errors.passcode)}
                  fullWidth
                  helperText={touched.passcode && errors.passcode}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <InputBase disabled inputProps={{ "aria-label": "naked" }} />
                <div className="s10"></div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  classes={{ root: "button", label: "button-label" }}
                  style={{ backgroundColor: "#3DB5E6" }}
                >
                  Verify
                </Button>

                {/*<div className="auth-padding"></div>*/}
                {/*<div className="auth-padding"></div>*/}
              </form>
            )}
          </Formik>
          <Formik
            initialValues={{
              email: location.state.email,
              submit: false,
            }}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                const trimmedEmail = location.state.email.trim().toLowerCase();
                await dispatch(resendSignUp(trimmedEmail));
                history.push({
                  pathname: "/auth/verify-code",
                  state: { email: trimmedEmail },
                });
              } catch (error) {
                let message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="s10"></div>
                <div align="center">
                  <span className="button-left-text">
                    Didn't receive a code?
                  </span>
                  <Button
                    style={{ paddingTop: "4px" }}
                    type="submit"
                    color="primary"
                    classes={{ label: "link-text" }}
                  >
                    Resend
                  </Button>
                  {isSubmitting ? (
                    <div className="resend-spinner">
                      <CircularProgress size={13} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </Wrapper>
      </Grid>
    </Grid>
  );
}

export default VerifyPasscode;
