import {
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";

const Pricing = () => {
  return (
    <Card mb={6}>
      <CardContent>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Typography variant="h5">EcoQube Pro Pricing</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ fontSize: "14px" }}>
              <ul style={{ paddingLeft: "2em" }}>
                <li>
                  <strong>
                    <u>45-unit Premium Case</u>
                  </strong>
                  <ul style={{ paddingLeft: "2em", listStyleType: "disc" }}>
                    <li>Price: $35.00/month</li>
                    <li>Minimum 6–month lease</li>
                    <li>Automatic monthly renewal after 6 months</li>
                    <li>Cancellation: 30-day advance notice</li>
                    <li>Other fees and costs </li>
                    <ul style={{ paddingLeft: "2em", listStyleType: "disc" }}>
                      <li>New customer one-time activation fee: $100.00</li>
                      <li>
                        Lost or damaged unit: $150.00 per unit for replacement
                      </li>
                      <ul style={{ paddingLeft: "2em", listStyleType: "disc" }}>
                        <li>
                          Customer is responsible for shipping and other related
                          costs
                        </li>
                      </ul>
                    </ul>
                    <li>
                      Payment: automatic charge to the designated payment method
                      (credit card)
                    </li>
                    <li>
                      Calibration: When calibration is due, a newly calibrated
                      replacement unit will be sent. The customer must return
                      the unit with expired calibration within 30 days upon
                      receiving the replacement unit
                    </li>
                    <li>
                      A $20.00 fee will be incurred after 20 days of expiration
                      unless an updated payment method is provided
                    </li>
                  </ul>
                </li>
              </ul>
              <ul style={{ paddingLeft: "2em", marginTop: "2em" }}>
                <li>
                  <strong>
                    <u>4-unit Pro Pack</u>
                  </strong>
                  <ul style={{ paddingLeft: "2em", listStyleType: "disc" }}>
                    <li>Price: $45.00/month</li>
                    <li>Minimum 6–month lease</li>
                    <li>Automatic monthly renewal after 6 months</li>
                    <li>Cancellation: 30-day advance notice</li>
                    <li>Other fees and costs </li>
                    <ul style={{ paddingLeft: "2em", listStyleType: "disc" }}>
                      <li>New customer one-time activation fee: $100.00</li>
                      <li>
                        Lost or damaged unit: $150.00 per unit for replacement
                      </li>
                      <ul style={{ paddingLeft: "2em", listStyleType: "disc" }}>
                        <li>
                          Customer is responsible for shipping and other related
                          costs
                        </li>
                      </ul>
                    </ul>
                    <li>
                      Payment: automatic charge to the designated payment method
                      (credit card)
                    </li>
                    <li>
                      Calibration: When calibration is due, a newly calibrated
                      replacement unit will be sent. The customer must return
                      the unit with expired calibration within 30 days upon
                      receiving the replacement unit
                    </li>
                    <li>
                      A $20.00 fee will be incurred after 20 days of expiration
                      unless an updated payment method is provided
                    </li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              href="https://ecosense.io/pages/lease-ecoqube-pro-radon-monitors"
              variant="contained"
              color="primary"
              mt={3}
              style={{ backgroundColor: "#4C46C1" }}
            >
              EcoQube Pro Monitor Order Form
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Pricing;
