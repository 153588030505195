import * as types from "../../../constants";

const initialState = {
    loading: false,
    testerList: [],
    currentTester: {},
    sort: { order: "desc", orderBy: "fullName" },
};

export default function reducer(state = initialState, action) {
    let filteredTesterList = [];

    switch (action.type) {
        case types.SU_TESTER_SORT:
            return {
                ...state,
                sort: { order: action.payload.order, orderBy: action.payload.orderBy },
            };
        case types.SU_TESTER_LOADING:
            return {
                ...state,
                loading: true,
            };
        case types.SU_TESTER_LOADING_DISABLE:
            return {
                ...state,
                loading: false,
            };
        case types.SU_RETRIEVE_TESTER_LIST:
            return {
                ...state,
                testerList: [...action.payload],
                loading: false,
            };

        case types.SU_UPDATE_TESTER:
            filteredTesterList = state.testerList.filter(
                (tester) => tester.id !== action.payload.id
            );

            return {
                ...state,
                testerList: [...filteredTesterList],
                loading: false,
            };

        case types.SU_DELETE_TESTER:
            filteredTesterList = state.testerList.filter(
                (tester) => tester.id !== action.payload
            );

            return {
                ...state,
                testerList: [...filteredTesterList],
                //loading: false,
            };
        case types.SU_RESET_TESTERS:
            return {
                ...state,
                currentTester: {},
                testerList: [],
            };
        default:
            return state;
    }
}
