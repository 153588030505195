import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./mocks";
import "promise-polyfill/src/polyfill";
import "unfetch/polyfill";
import "abortcontroller-polyfill";

import { Provider } from "react-redux";
import store from "./models/store/index";
import Amplify from "aws-amplify";
import { awsAmplifyAuth } from "./env";

Amplify.configure({
  Auth: awsAmplifyAuth,
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
