import * as yup from "yup";
import {
  validZipcodeRegex,
  validEmailRegex,
  validPhoneNumberRegex,
  validNameRegex,
  validPwdRegex,
} from "../../../utils/AuthValidation";

export const signUpValidation = yup.object({
  email: yup
    .string()
    .matches(validEmailRegex, "Email is invalid")
    .required("required"),
  name: yup
    .string()
    .matches(validNameRegex, "Name is invalid")
    .required("required"),
  password: yup
    .string()
    .matches(
      validPwdRegex,
      "Password must contain 8 or more characters, uppercase letters, lowercase letters and numbers."
    )
    .required("required"),
  confirmPassword: yup
    .string()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    })
    .required("required"),
  companyName: yup.string().required("required"),
  companyPhone: yup
    .string()
    .matches(validPhoneNumberRegex, "Phone number is invalid"),
  address1: yup.string().required("required"),
  address2: yup.string(),
  city: yup.string(),
  state: yup.string(),
  zipcode: yup.string().matches(validZipcodeRegex, "Zip is invalid"),
});
