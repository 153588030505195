import * as yup from "yup";
import {
  validZipcodeRegex,
  validEmailRegex,
  validPhoneNumberRegex,
  validNameRegex,
} from "../../../utils/AuthValidation";

export const newProjectFormValidation = yup.object({
  projectName: yup.string().required("required"),
  clientName: yup
    .string()
    .matches(validNameRegex, "Name is invalid")
    .required("required"),
  clientEmailAddress: yup
    .string()
    .matches(validEmailRegex, "Email address is invalid")
    .required("required"),
  clientPhoneNumber: yup.string(),
  startDate: yup.date(),
  endDate: yup.date(),
  address1: yup.string().required("required"),
  address2: yup.string(),
  city: yup.string().required("required"),
  state: yup.string().required("required"),
  zipcode: yup
    .string()
    .matches(validZipcodeRegex, "zip is invalid")
    .required("required"),
  buildingType: yup.string().required("required"),
  structureType: yup.string().required("required"),
  testPurpose: yup.string().required("required"),
});

export const projectDetailValidation = yup.object({
  projectName: yup.string().required("required"),
  clientName: yup
    .string()
    .matches(validNameRegex, "Name is invalid")
    .required("required"),
  clientEmailAddress: yup
    .string()
    .matches(validEmailRegex, "Email address is invalid")
    .required("required"),
  clientPhoneNumber: yup.string(),
  startDate: yup.date().nullable().required("Start date is required"),
  endDate: yup.date().nullable().required("End date is required"),
});

export const propertyDetailValidation = yup.object({
  address1: yup.string().required("required"),
  address2: yup.string(),
  city: yup.string().required("required"),
  state: yup.string().required("required"),
  zipcode: yup
    .string()
    .matches(validZipcodeRegex, "zip is invalid")
    .required("required"),
  buildingType: yup.string().required("required"),
  structureType: yup.string().required("required"),
  testPurpose: yup.string().required("required"),
});

export const companyInfoValidation = yup.object({
  companyName: yup.string().required("required"),
  phoneNumber: yup
    .string()
    .matches(validPhoneNumberRegex, "Phone number is invalid")
    .required("required"),
  address1: yup.string().required("required"),
  address2: yup.string(),
  city: yup.string().required("required"),
  state: yup.string().required("required"),
  zipcode: yup
    .string()
    .matches(validZipcodeRegex, "zip is invalid")
    .required("required"),
});
