import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useHistory, useLocation } from "react-router-dom";
import {
  Avatar,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { Alert as MuiAlert } from "@material-ui/lab";
import { updateCompanyInfo } from "../../models/actions/authActions";
import {getCompanyInfo, getCompanyLogo, updateCompanyLogo} from "../../models/services/authService";
import MenuItem from "@material-ui/core/MenuItem";
import { states } from "../../constants/values";
import {phoneInputMask, zipcodeInputMask} from "../dashboards/Projects/utils";
import { companyInfoValidation } from "../forms/Schema/formValidation";
import { useSnackbar } from "notistack";
import EcoButton from "../../components/EcoButton";
import Space from "../../components/Space";
import {CloudUpload as MuiCloudUpload} from "@material-ui/icons";
import imageToBase64 from "image-to-base64/browser";
import logoDefault from "../../assets/img/ic_company_default.png"

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);
const TextField = styled(MuiTextField)(spacing);
const CloudUpload = styled(MuiCloudUpload)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;

const ImgLogo = styled("img")`
  width: 150px;
  height: 70px;
  object-fit: contain;
  margin: 0 auto ${(props) => props.theme.spacing(2)}px;
`;


function CompanyInfo() {
  const [errorUpload, setErrorUpload] = useState();
  const [image, setImage] = useState(logoDefault);
  const { enqueueSnackbar } = useSnackbar();

  const statesKeys = Object.keys(states);

  const [showAlert, setShowAlert] = useState(false);
  const [initialValues, setInitialValues] = useState({
    companyName: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
  });
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSpinnerUpload, setShowSpinnerUpload] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  location.state = {
    email: localStorage.getItem("0xaccountId"),
    name: localStorage.getItem("0xuserId"),
  };
  location.state.email = localStorage.getItem("0xaccountId");
  if (!location.state.email) {
    history.push("/auth/sign-in");
  }

  useEffect(() => {
    const getData = async () => {
      const trimmedEmail = location.state.email.trim().toLowerCase();
      const companyInfo = (await getCompanyInfo(trimmedEmail)).data;

      if (companyInfo) {
        setInitialValues({
          companyName: companyInfo.companyName,
          phoneNumber: companyInfo.companyPhone,
          address1: companyInfo.companyAddress.address1,
          address2: companyInfo.companyAddress.address2,
          city: companyInfo.companyAddress.city,
          state: companyInfo.companyAddress.state,
          zipcode: companyInfo.companyAddress.zipcode,
        });
      }
    };

    getData();

    const updateImage = async () => {
      const imgURL = (await getCompanyLogo(location.state.email));
      if (imgURL) {
        const imgURL2 = imgURL + "?a=" + Math.random();
        setImage(imgURL2);
      } else {
        setImage(logoDefault);
      }
    };
    updateImage();
  }, [location.state.email]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: companyInfoValidation,
    onSubmit: async (values, submitProps) => {
      setShowAlert(false);
      setShowSpinner(true);

      const trimmedEmail = location.state.email.trim().toLowerCase();

      try {
        // Register Subscription Information for Mitigator
        dispatch(
          updateCompanyInfo({
            email: trimmedEmail,
            companyName: values.companyName,
            phoneNumber: values.phoneNumber,
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
          })
        );
      } catch (error) {
        const message = "Error fetching project list";
        enqueueSnackbar(message, { variant: "error" });
      }

      submitProps.resetForm({ values });
      setShowAlert(true);
      setShowSpinner(false);
    },
  });

  const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

  const createPutImageRequest = (email, fileName, base64EncodedImageData) => {
    const request = {
      email: email,
      file_name: fileName,
      image: base64EncodedImageData,
    };
    return request;
  };

  const handleImageChanged = async (e) => {
    setShowAlert(false);
    setShowSpinnerUpload(true);
    setErrorUpload("");
    try {
      const email = location.state.email;
      const file = e.target.files[0];
      const imageURL = URL.createObjectURL(e.target.files[0]);
      const base64EncodedImageData = await toBase64(file).catch((e) =>
          Error(e)
      );
      if (base64EncodedImageData instanceof Error) {
        console.log("Error: ", base64EncodedImageData.message);
        setErrorUpload(base64EncodedImageData.message);
        return;
      }

      // Upload image to cloud
      const fileName = email + ".jpg";
      imageToBase64(imageURL)
          .then(async (response) => {
            const putImageRequest = createPutImageRequest(
                email,
                fileName,
                base64EncodedImageData
            );
            const updateCompanyLogoResponse = await updateCompanyLogo(
                putImageRequest
            );
            setImage(imageURL);
            if (updateCompanyLogoResponse.status === 200) {
              setShowSpinnerUpload(false);
              setShowAlert(true);
            } else {
              setErrorUpload("Uploading image failed");
            }
            setShowAlert(true);
          })
          .catch((error) => {
            setShowSpinnerUpload(false);
            throw error;
          });
    } catch (error) {
      setErrorUpload(error.message);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {showAlert && (
        <Alert
          mt={2}
          mb={1}
          severity={formik.errors.submit || errorUpload ? "error" : "success"}
          onClose={() => {
            setShowAlert(false);
          }}
        >
          {formik.errors.submit
              ? formik.errors.submit
              : errorUpload
              ? errorUpload: "Successfully saved."}
        </Alert>
      )}
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Company information
          </Typography>

          <Grid container spacing={6}>
            <Grid item md={8}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <TextField
                      name="companyName"
                      label="Company name"
                      value={formik.values.companyName}
                      fullWidth
                      error={Boolean(
                          formik.touched.companyName && formik.errors.companyName
                      )}
                      helperText={
                          formik.touched.companyName && formik.errors.companyName
                      }
                      onChange={formik.handleChange}
                      my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                      name="phoneNumber"
                      label="Phone number"
                      value={formik.values.phoneNumber}
                      error={Boolean(
                          formik.touched.phoneNumber && formik.errors.phoneNumber
                      )}
                      fullWidth
                      helperText={
                          formik.touched.phoneNumber && formik.errors.phoneNumber
                      }
                      onChange={formik.handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputComponent: phoneInputMask,
                      }}
                      my={2}
                  />
                </Grid>
              </Grid>
              <TextField
                  name="address1"
                  label="Address"
                  value={formik.values.address1}
                  error={Boolean(formik.touched.address1 && formik.errors.address1)}
                  fullWidth
                  helperText={formik.touched.address1 && formik.errors.address1}
                  onChange={formik.handleChange}
                  my={2}
              />
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <TextField
                      name="city"
                      label="City"
                      value={formik.values.city}
                      error={Boolean(formik.touched.city && formik.errors.city)}
                      fullWidth
                      helperText={formik.touched.city && formik.errors.city}
                      onChange={formik.handleChange}
                      my={2}
                  />
                </Grid>
                <Grid item md={3}>
                  <TextField
                      id="state"
                      type="text"
                      name="state"
                      label="State"
                      value={formik.values.state}
                      error={Boolean(formik.touched.state && formik.errors.state)}
                      fullWidth
                      helperText={formik.touched.state && formik.errors.state}
                      onChange={formik.handleChange}
                      my={2}
                      SelectProps={{ MenuProps: { style: { maxHeight: 400 } } }}
                      select
                  >
                    {statesKeys.map((key) => {
                      return (
                          <MenuItem key={key} value={states[key]}>
                            {states[key]}
                          </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item md={3}>
                  <TextField
                      name="zipcode"
                      label="Zipcode"
                      value={formik.values.zipcode}
                      error={Boolean(formik.touched.zipcode && formik.errors.zipcode)}
                      fullWidth
                      helperText={formik.touched.zipcode && formik.errors.zipcode}
                      onChange={formik.handleChange}
                      my={2}
                      InputProps={{
                        inputComponent: zipcodeInputMask,
                      }}
                  />
                </Grid>
              </Grid>
              <div className="s30"></div>
              <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent={"flex-start"}
                  alignItems={"center"}
              >
                <EcoButton
                    type="submit"
                    variant="contained"
                    disabled={!formik.dirty}
                    color="primary"
                >
                  Save Changes
                </EcoButton>
                {showSpinner ? (
                    <div
                        className="save-changes-spinner"
                        style={{ marginLeft: "20px" }}
                    >
                      <CircularProgress size={20} />
                    </div>
                ) : (
                    <></>
                )}
              </Box>
            </Grid>

            <Grid item
                  md={4}
                  container
                  align="center"
                  justify="center"
                  direction="column"
            >
              <CenteredContent>
                {image !== null ?
                    (
                        <ImgLogo
                            alt="logo" src={image} />
                    )
                    :
                    null}
                <Space height={"10px"} />
                <div className="s10"></div>
                <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-logo-file"
                    multiple
                    type="file"
                    onChange={handleImageChanged}
                />
                <label htmlFor="raised-button-logo-file">
                  <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent={"center"}
                      alignItems={"center"}
                  >
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                    >
                      {
                        <>
                          <Button
                              variant="contained"
                              style={{
                                background: "#3DB5E6",
                                color: "#FFFFFF",
                              }}
                              component="span"
                              classes={{
                                root: "button",
                                label: "button-label",
                              }}
                          >
                            <CloudUpload mr={2} /> Upload
                          </Button>
                        </>
                      }
                      {showSpinnerUpload ? (
                          <div className="settings-changes-spinner">
                            <CircularProgress size={20} />
                          </div>
                      ) : (
                          <></>
                      )}
                    </Box>
                  </Box>
                  <div className="s10"></div>
                  <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                  >
                    For best results, use an image at least 300px by 128px
                    in .jpg format
                  </Typography>
                </label>
              </CenteredContent>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
}

export default CompanyInfo;
