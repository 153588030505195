import Header from "./Header";
import Contact from "./Contact";
import Resources from "./Resources";
import { Grid } from "@material-ui/core";
import StateReport from "./StateReport";
import Pricing from "./Pricing";
import FAQ from "./FAQ";

const SupportPage = () => {
  return (
    <div>
      <Header />
      <Grid container spacing={6}>
        <Grid item style={{ width: "100%" }}>
          <Contact />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Resources />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <StateReport />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Pricing />
        </Grid>
        {/* <Grid item style={{ width: "100%" }}>
          <FAQ />
        </Grid> */}
      </Grid>
    </div>
  );
};

export default SupportPage;
