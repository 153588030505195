import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useHistory, useLocation } from "react-router-dom";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  FormControl as MuiFormControl,
  Grid,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { changePassword } from "../../models/actions/authActions";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { validPwdRegex } from "../../utils/AuthValidation";
import { Alert as MuiAlert } from "@material-ui/lab";

import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import "../../assets/css/settings.css";
const Alert = styled(MuiAlert)(spacing);

const Card = styled(MuiCard)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const Button = styled(MuiButton)(spacing);

function ChangePassword() {
  const [changed, setChanged] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  location.state = {
    email: localStorage.getItem("0xaccountId"),
    name: localStorage.getItem("0xuserId"),
  };
  location.state.email = localStorage.getItem("0xaccountId");
  if (!location.state.email) {
    history.push("/auth/sign-in");
  }

  const defaultPwd = {
    currentPassForChange: "",
    newPassword: "",
    confirmPassword: "",
    showCurrentPassForChange: false,
    showNewPassword: false,
    showConfirmPassword: false,
  };

  const [pwdValues, setPwdValues] = React.useState(defaultPwd);

  useEffect(() => {
    setPwdValues(defaultPwd);
    return () => {
      setPwdValues(defaultPwd);
    };
  }, []);

  const checkPwdFieldFilled = () => {
    return (
      pwdValues.currentPassForChange &&
      pwdValues.newPassword &&
      pwdValues.confirmPassword
    );
  };

  const handlePwdChange = (prop) => (event) => {
    setPwdValues({ ...pwdValues, [prop]: event.target.value });
    if (checkPwdFieldFilled()) {
      setChanged(true);
    } else setChanged(false);
  };

  const handleClickShowCurrentPassForChange = () => {
    setPwdValues({
      ...pwdValues,
      showCurrentPassForChange: !pwdValues.showCurrentPassForChange,
    });
  };

  const handleClickShowNewPassword = () => {
    setPwdValues({ ...pwdValues, showNewPassword: !pwdValues.showNewPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setPwdValues({
      ...pwdValues,
      showConfirmPassword: !pwdValues.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateAccountInfo = (values) => {
    const errors = {};

    if (!pwdValues.currentPassForChange) {
      errors.currentPassForChange = "Required";
    } else if (!validPwdRegex.test(pwdValues.currentPassForChange)) {
      errors.currentPassForChange =
        "It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.";
    }

    if (!pwdValues.newPassword) {
      errors.newPassword = "Required";
    } else if (!validPwdRegex.test(pwdValues.newPassword)) {
      errors.newPassword =
        "It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.";
    }

    if (!pwdValues.confirmPassword) {
      errors.confirmPassword = "Required";
    } else if (!validPwdRegex.test(pwdValues.confirmPassword)) {
      errors.confirmPassword =
        "It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.";
    } else if (
      !errors.currentPassForChange &&
      !errors.newPassword &&
      pwdValues.newPassword !== pwdValues.confirmPassword
    ) {
      errors.confirmPassword =
        "Your new password and confirmation password do not match.";
    }

    return errors;
  };

  return (
    <>
      <Formik
        initialValues={{
          submit: false,
        }}
        validate={validateAccountInfo}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          //init
          setShowAlert(false);
          setShowSpinner(true);

          try {
            await dispatch(
              changePassword({
                currentPassword: pwdValues.currentPassForChange,
                newPassword: pwdValues.newPassword,
              })
            );
            setPwdValues(defaultPwd);
            setChanged(false);
          } catch (error) {
            let message = error.message || "Something went wrong";
            if (error.code === "UserNotConfirmedException") {
              message = "Account not verified yet";
            } else if (error.code === "PasswordResetRequiredException") {
              message = "Existing user found. Please reset your password";
            } else if (error.code === "NotAuthorizedException") {
              message = error.message;
            } else if (error.code === "UserNotFoundException") {
              message = "User does not exist!";
            } else if (error.code === "UsernameExistsException") {
              message = "User already exists!";
            } else if (error.code === "InvalidParameterException") {
              message =
                "Trailing spaces are not allowed in your password \n or one of parameters is invalid";
            }

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
          setShowAlert(true);
          setShowSpinner(false);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {showAlert && (
              <Alert
                mt={2}
                mb={1}
                severity={errors.submit ? "error" : "success"}
                onClose={() => {
                  setShowAlert(false);
                }}
              >
                {errors.submit ? errors.submit : "Successfully saved."}
              </Alert>
            )}
            <Card mb={6}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item md={12}>
                    <Typography variant="h6" gutterBottom>
                      Change Password
                    </Typography>
                    <FormControl fullWidth my={2}>
                      <InputLabel
                        htmlFor="standard-current-password-for-change"
                        error={errors.currentPassForChange ? true : false}
                      >
                        Current Password
                      </InputLabel>
                      <Input
                        id="standard-current-password-for-change"
                        autoComplete="new-password"
                        aria-describedby="current-password-helper"
                        type={
                          pwdValues.showCurrentPassForChange
                            ? "text"
                            : "password"
                        }
                        value={pwdValues.currentPassForChange}
                        onChange={handlePwdChange("currentPassForChange")}
                        // onBlur={handleBlur}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              aria-label="toggle password visibility"
                              onClick={handleClickShowCurrentPassForChange}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {pwdValues.showCurrentPassForChange ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {errors.currentPassForChange && (
                        <FormHelperText
                          id="current-password-helper"
                          error={true}
                        >
                          {errors.currentPassForChange}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl fullWidth my={2}>
                      <InputLabel
                        htmlFor="standard-new-password"
                        error={errors.newPassword ? true : false}
                      >
                        New Password
                      </InputLabel>
                      <Input
                        id="standard-new-password"
                        autoComplete="new-password"
                        aria-describedby="new-password-helper"
                        type={pwdValues.showNewPassword ? "text" : "password"}
                        value={pwdValues.newPassword}
                        onChange={handlePwdChange("newPassword")}
                        // onBlur={handleBlur}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              aria-label="toggle password visibility"
                              onClick={handleClickShowNewPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {pwdValues.showNewPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {errors.newPassword && (
                        <FormHelperText id="new-password-helper" error={true}>
                          {errors.newPassword}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl fullWidth my={2}>
                      <InputLabel
                        htmlFor="standard-confirm-password"
                        error={errors.confirmPassword ? true : false}
                      >
                        Confirm New Password
                      </InputLabel>
                      <Input
                        id="standard-confirm-password"
                        autoComplete="new-password"
                        aria-describedby="confirm-password-helper"
                        type={
                          pwdValues.showConfirmPassword ? "text" : "password"
                        }
                        value={pwdValues.confirmPassword}
                        onChange={handlePwdChange("confirmPassword")}
                        // onBlur={handleBlur}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {pwdValues.showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {errors.confirmPassword && (
                        <FormHelperText
                          id="confirm-password-helper"
                          error={true}
                        >
                          {errors.confirmPassword}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    container
                    align="center"
                    justify="center"
                    direction="column"
                  ></Grid>
                </Grid>
                <div className="s10"></div>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  {
                    <>
                      {changed ? (
                        <Button
                          type="submit"
                          variant="contained"
                          style={{
                            background: "#3DB5E6",
                            color: "#FFFFFF",
                          }}
                          disabled={isSubmitting}
                          classes={{ root: "button", label: "button-label" }}
                        >
                          Save Changes
                        </Button>
                      ) : (
                        <Button
                          classes={{ root: "button", label: "button-label" }}
                          style={{ background: "#E0E0E0", color: "#FFFFFF" }}
                          disabled
                        >
                          Save Changes
                        </Button>
                      )}
                    </>
                  }
                  {showSpinner ? (
                    <div className="settings-changes-spinner" style={{marginLeft: "20px"}}>
                      <CircularProgress size={20} />
                    </div>
                  ) : (
                    <></>
                  )}
                </Box>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </>
  );
}

export default ChangePassword;
