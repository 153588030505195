export const apiEndPoint = "https://pro-dev.ecosense.io/api/v1";
// export const apiEndPoint = "https://dev-api.cloud.ecosense.io/api/v1";
export const accessUrl = "https://dashboard.pro-dev.ecosense.io";
export const awsXApiKey = "WdsIuJkuEF7B75Xdc0vo56pUI63rCPbm7fzI7iPF";
export const showSingleMenu = true;

// export const awsAmplifyAuth = {
//   region: "us-east-1",
//   userPoolId: "us-east-1_H8dKz8FKM",
//   identityPoolId: "us-east-1:3f95577a-a51f-4d52-b2b8-9c39a294fbf2",
//   userPoolWebClientId: "4sdm0obig8em99fg93m5sgtt37",
// };

// export const awsAmplifyAuth = {
//   region: "us-west-2",
//   userPoolId: "us-west-2_s12RhfELW",
//   identityPoolId: "us-east-1:3f95577a-a51f-4d52-b2b8-9c39a294fbf2", //us-west-2:7faa4471-7d6f-4e56-a087-2ced7cdcc80e",
//   userPoolWebClientId: "286s28b9paspvukjo5foa805rp",
// };

export const awsAmplifyAuth = {
  region: "us-west-2",
  userPoolId: "us-west-2_SB2SWh8kT",
  identityPoolId: "us-west-2:7faa4471-7d6f-4e56-a087-2ced7cdcc80e",
  userPoolWebClientId: "761a7nofbf3gdip5c9pik0k1mo",
};
