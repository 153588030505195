import React from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import { Divider as MuiDivider, Typography } from "@material-ui/core";

import { spacing } from "@material-ui/system";
import AccountInfo from "./AccountInfo";
import ChangePassword from "./ChangePassword";
import CompanyInfo from "./CompanyInfo";

const Divider = styled(MuiDivider)(spacing);

function Setting() {
  return (
    <React.Fragment>
      <Helmet title="Settings" />

      <Typography variant="h3">Settings</Typography>

      <Divider my={6} />
      <AccountInfo />
      <CompanyInfo />
      <ChangePassword />
    </React.Fragment>
  );
}

export default Setting;
