import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";

const StateReport = () => {
  const Mailto = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return (
      <Button
        variant="contained"
        color="primary"
        mt={3}
        href={`mailto:${email}${params}`}
      >
        {children}
      </Button>
    );
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Grid container direction="column" spacing={6}>
          <Grid item>
            <Typography variant="h5">Request state report</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ fontSize: "14px" }}>
              To request a state radon report, please contact us via email at
              lab@ecosense.io. <br />
              Be sure to include the device's serial number and your account
              details in your email. <br />
              Once we receive your email, we will review the data from the
              server and reach out to you with further information.
            </Typography>
          </Grid>
          <Grid item>
            <Mailto
              email="lab@ecosense.io"
              subject="Request state report"
              body="Please provide device's serial number and your account details in your email."
            >
              Request state report
            </Mailto>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StateReport;
