import * as types from "../../../constants";

const initialState = {
  loading: false,
  reportList: [],
  reportFilterList: [],
  filterEnabled: false,
  reportListFilter: {
    duplicate: 0,
    anomaly: 0,
    all: 1,
  },
  reportDetails: {},
  sort: { order: "desc", orderBy: "measurementStarted" },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SU_REPORT_SORT:
      return {
        ...state,
        sort: { order: action.payload.order, orderBy: action.payload.orderBy },
      };
    case types.SU_REPORT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SU_REPORT_LOADING_DISABLE:
      return {
        ...state,
        loading: false,
      };
    case types.SU_RETRIEVE_REPORT_LIST:
      return {
        ...state,
        reportList: [...action.payload],
        loading: false,
      };

    case types.SU_RETRIEVE_REPORT_LIST_FILTER:
      let duplicatesList = [];
      let anomaliesList = [];

      if (action.payload.duplicate) {
        duplicatesList = state.reportList.filter(
          (report) => report.duplicateTest === 1 || report.duplicateTest === 2
        );
      }

      if (action.payload.anomaly) {
        anomaliesList = state.reportList.filter((report) =>
          report.anomalies.includes(1)
        );
      }

      if (action.payload.all) {
        return {
          ...state,
          reportFilterList: [],
          filterEnabled: false,
          reportListFilter: action.payload,
        };
      }

      const filteredReportList = duplicatesList.concat(anomaliesList);
      const uniqueList = Array.from(new Set(filteredReportList));

      return {
        ...state,
        reportFilterList: uniqueList,
        filterEnabled: true,
        reportListFilter: action.payload,
      };

    case types.SU_RETRIEVE_REPORT_DETAIL:
      return {
        ...state,
        reportDetails: action.payload,
        loading: false,
      };
    case types.SU_DELETE_REPORT:
      let newReportList = [];

      newReportList = state.reportList.filter(
        (report) => report.reportId !== action.payload
      );

      return {
        ...state,
        reportList: [...newReportList],
        loading: false,
      };
    case types.SU_RESET_REPORTS:
      return {
        ...state,
        reportDetails: {},
        reportList: [],
      };
    default:
      return state;
  }
}
