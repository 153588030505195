import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useFormik } from "formik";
import {
  createCompanyInfo,
  signUpAsOwner,
} from "../../models/actions/authActions";
import bg from "../../assets/img/bg-half.png";
import EQBrandLogo from "../../assets/img/ecosense-brand-logo-negative-white.png";
import EQBrand from "../../assets/img/ecosense-brandmark-positive.png";
import EQLogo from "../../assets/img/EQ200_Logo_wh.png";
import {
  Grid,
  Paper,
  TextField as MuiTextField,
  Typography,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import "../../assets/css/common.css";
import "../../assets/css/auth.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { signUpValidation } from "../forms/Schema/signUpValidation";
import { states } from "../../constants/values";
import { phoneInputMask } from "../dashboards/Projects/utils";
import EcoButton from "../../components/EcoButton";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("xs")} {
    padding-top: ${(props) => props.theme.spacing(10)}px;
    padding-bottom: ${(props) => props.theme.spacing(10)}px;
    padding-left: ${(props) => props.theme.spacing(15)}px;
    padding-right: ${(props) => props.theme.spacing(15)}px;
  }
`;

function SignUp() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(1617));

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const statesKeys = Object.keys(states);

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      password: "",
      confirmPassword: "",
      companyName: "",
      companyPhone: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
    },
    validationSchema: signUpValidation,
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      const trimmedEmail = values.email.trim().toLowerCase();
      try {
        await dispatch(
          signUpAsOwner({
            email: trimmedEmail,
            name: values.name,
            password: values.password,
          })
        )
          .then(async () => {
            dispatch(
              createCompanyInfo({
                email: trimmedEmail,
                companyName: values.companyName,
                companyPhone: values.companyPhone,
                companyAddress: {
                  address1: values.address1,
                  address2: values.address2,
                  city: values.city,
                  state: values.state,
                  zipcode: values.zipcode,
                },
              })
            );
          })
          .then(async () => {
            history.push({
              pathname: "/auth/verify-code",
              state: { email: trimmedEmail },
            });
          });
      } catch (error) {
        let message = error.message || "Something went wrong";
        if (error.code === "UserNotConfirmedException") {
          message = "Account not verified yet";
        } else if (error.code === "PasswordResetRequiredException") {
          message = "Existing user found. Please reset your password";
        } else if (error.code === "NotAuthorizedException") {
          message = error.message;
        } else if (error.code === "UserNotFoundException") {
          message = "User does not exist!";
        } else if (error.code === "UsernameExistsException") {
          message = "User already exists!";
        } else if (error.code === "InvalidParameterException") {
          message =
            "Trailing spaces are not allowed in your password \n or one of parameters is invalid";
        }

        setStatus({ success: false });
        setErrors({ submit: message });
        setSubmitting(false);
      }
    },
  });

  return (
    <Grid
      container
      direction="row"
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
      }}
    >
      {matches && (
        <Grid
          xs={6}
          item
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: theme.palette.primary.main,
            width: "100vw",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid item style={{ marginTop: "auto" }}>
            <img
              style={{
                width: "100%",
                height: "80px",
                objectFit: "contain",
              }}
              src={EQLogo}
              alt="ecosense logo"
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h1"
              style={{
                marginTop: 26,
                textAlign: "center",
                color: "white",
                fontFamily: "avenir",
                fontWeight: "500",
                width: "100%",
              }}
            >
              Radon Testing with Ecosense Solution Fast and Accurately
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: "auto" }}>
            <img
              style={{
                width: "100%",
                height: "200px",
                objectFit: "contain",
              }}
              src={EQBrandLogo}
              alt="ecosense brand logo"
            />
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs={matches ? 6 : 12}
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Wrapper
          style={{
            width: "680px",
          }}
        >
          <Helmet title="Sign Up" />
          <div align="center">
            <img
              style={{
                width: "200px",
                height: "150px",
                objectFit: "contain",
              }}
              src={EQBrand}
              alt="ecosense brand"
            />
          </div>
          <div align="center">
            <span className="account-title-text">Create your account</span>
          </div>
          <div className="s20"></div>
          <div align="center">
            <span
              className="account-check-text"
              style={{ color: theme.palette.primary.main }}
            >
              Have an account?{" "}
              <Link
                className="account-check-link"
                to="/auth/sign-in"
                style={{ color: theme.palette.primary.main }}
              >
                SIGN IN
              </Link>
            </span>
          </div>
          <div className="s30"></div>
          <form onSubmit={formik.handleSubmit}>
            {formik.errors.submit && (
              <>
                <Alert mt={2} mb={1} severity="warning">
                  {formik.errors.submit}
                </Alert>
                <div className="s10"></div>
              </>
            )}
            <Typography variant="subtitle2" style={{ fontSize: "14px" }}>
              Account information
            </Typography>
            <TextField
              id="email"
              type="text"
              name="email"
              label="Email Address *"
              value={formik.values.email}
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
              my={2}
            />
            <TextField
              id="name"
              type="text"
              name="name"
              label="Full name *"
              value={formik.values.name}
              error={Boolean(formik.touched.name && formik.errors.name)}
              fullWidth
              helperText={formik.touched.name && formik.errors.name}
              onChange={formik.handleChange}
              my={2}
            />
            <TextField
              id="password"
              type={showPassword ? "text" : "password"}
              name="password"
              label="Password *"
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              onChange={formik.handleChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              my={2}
            />
            <TextField
              id="confirmPassword"
              type={showPassword ? "text" : "password"}
              name="confirmPassword"
              label="Confirm Password *"
              value={formik.values.confirmPassword}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              onChange={formik.handleChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              my={2}
            />
            <div className="s30"></div>
            <Typography variant="subtitle2" style={{ fontSize: "14px" }}>
              Company information
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="companyName"
                  type="text"
                  name="companyName"
                  label="Company Name *"
                  value={formik.values.companyName}
                  fullWidth
                  error={Boolean(
                    formik.touched.companyName && formik.errors.companyName
                  )}
                  helperText={
                    formik.touched.companyName && formik.errors.companyName
                  }
                  onChange={formik.handleChange}
                  my={2}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="companyPhone"
                  type="text"
                  name="companyPhone"
                  label="Phone Number"
                  value={formik.values.companyPhone}
                  error={
                    formik.touched.companyPhone &&
                    Boolean(formik.errors.companyPhone)
                  }
                  helperText={
                    formik.touched.companyPhone && formik.errors.companyPhone
                  }
                  onChange={formik.handleChange}
                  fullWidth
                  my={2}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: phoneInputMask,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  name="address1"
                  label="Address *"
                  value={formik.values.address1}
                  error={Boolean(
                    formik.touched.address1 && formik.errors.address1
                  )}
                  fullWidth
                  helperText={formik.touched.address1 && formik.errors.address1}
                  onChange={formik.handleChange}
                  my={2}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="address2"
                  label="Address 2"
                  value={formik.values.address2}
                  error={Boolean(
                    formik.touched.address2 && formik.errors.address2
                  )}
                  fullWidth
                  helperText={formik.touched.address2 && formik.errors.address2}
                  onChange={formik.handleChange}
                  my={2}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="city"
                  label="City"
                  value={formik.values.city}
                  error={Boolean(formik.touched.city && formik.errors.city)}
                  fullWidth
                  helperText={formik.touched.city && formik.errors.city}
                  onChange={formik.handleChange}
                  my={2}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="state"
                  type="text"
                  name="state"
                  label="State"
                  value={formik.values.state}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                  onChange={formik.handleChange}
                  fullWidth
                  SelectProps={{ MenuProps: { style: { maxHeight: 400 } } }}
                  my={2}
                  select
                >
                  {statesKeys.map((key) => {
                    return (
                      <MenuItem key={key} value={states[key]}>
                        {states[key]}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  name="zipcode"
                  label="Zipcode"
                  value={formik.values.zipcode}
                  error={Boolean(
                    formik.touched.zipcode && formik.errors.zipcode
                  )}
                  fullWidth
                  helperText={formik.touched.zipcode && formik.errors.zipcode}
                  onChange={formik.handleChange}
                  my={2}
                />
              </Grid>
            </Grid>
            <div className="s30"></div>
            <EcoButton
              type="submit"
              fullWidth
              variant="contained"
              disabled={!formik.dirty}
              classes={{ root: "button", label: "button-label" }}
            >
              Create an account
            </EcoButton>
          </form>
          <div className="s20"></div>
          <div align="center">
            <span className="signup-terms-text">
              By continuing, you're agreeing to the <br />
              <a
                className="signup-terms-link"
                href="https://ecosense.io/policies/terms-of-service"
                target="_self"
                style={{ color: theme.palette.primary.main }}
              >
                Ecosense Terms of Service and Privacy Policy
              </a>
            </span>
          </div>
        </Wrapper>
      </Grid>
    </Grid>
  );
}

export default SignUp;
