import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../assets/css/common.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Avatar, Grid, Typography,
} from "@material-ui/core";

import { signOut } from "../models/actions/authActions";
import ecosenseLogo from "../assets/img/ecosense.png";
import ACCOUNT_ECO_LOGO from "../assets/img/Account_Eco_Logo.png";
import Box from "@material-ui/core/Box";
import { Auth } from "aws-amplify";
import { getUserPhoto } from "../models/services/authService";
import _ from "lodash";
import emptyWhiteAvatar from "../assets/img/empty-profile-small.jpg";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import {decodeRx} from '../views/dashboards/Projects/utils';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const BigAvatar = styled(Avatar)`
  width: 32px;
  height: 32px;
`;

const theme = createMuiTheme({
  overrides: {
    MuiList: {
      root: {
        width: "120px",
      },
    },
  },
});

function UserDropdown() {
  const [name, setName] = useState();
  const [image, setImage] = useState(emptyWhiteAvatar);
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const testUser = decodeRx(localStorage.getItem('0xXRurEm'));

  useEffect(() => {
    // Update Full Name
    const updateName = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const name = user.attributes.name;
      setName(name);
    };
    updateName();

    // Load Image
    const updateImage = async () => {
      location.state.email = localStorage.getItem("0xaccountId");
      const imgURL = (await getUserPhoto(location.state.email)) + "?a=" + Math.random();
      if (imgURL) {
        setImage(imgURL);
        localStorage.setItem("0xaccountImage", imgURL);
      } else {
        setImage(null);
      }
    };

    //if (location && _.get(location, "state.email")) {
    const userImg = localStorage.getItem("0xaccountImage");
    if (userImg === null){
      updateImage();
    }
    else{
      setImage(userImg);
    }
  }, []);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const gotoSettings = () => {
    history.push("/settings/account");
  };

  const handleSignOut = async () => {
    await dispatch(signOut());
    localStorage.clear();
    history.push("/auth/sign-in");
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={"center"}
        alignItems={"center"}
      >
        {testUser?
        <Typography
          variant="h6"
          style={{
            color: 'red',
            width: "350px",
            paddingTop: 0,
            paddingBottom: 0,
            display: "flex",
            justifyContent: "left",
          }}
        >
          Be Cautious! Signed in as Administrator
        </Typography>:<></>}
        <BigAvatar alt="Remy Sharp" src={image} />
        <Box style={{ width: "24px" }} />
        <span className="profile-name-text">
          {localStorage.getItem("0xuserId")}
        </span>

        <Tooltip title="Account">
          <IconButton
            aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
            aria-haspopup="true"
            onClick={toggleMenu}
            color="inherit"
          >
            <ExpandMoreIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <ThemeProvider theme={theme}>
        <Menu
          id="menu-appbar"
          anchorEl={anchorMenu}
          open={Boolean(anchorMenu)}
          onClose={closeMenu}
        >
          <MenuItem style={{ fontSize: "13px" }} onClick={gotoSettings}>
            Settings
          </MenuItem>
          <MenuItem style={{ fontSize: "13px" }} onClick={handleSignOut}>
            Sign out
          </MenuItem>
        </Menu>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default UserDropdown;
