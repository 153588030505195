import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import { resendSignUp } from "../../models/actions/authActions";
import bg from "../../assets/img/bg-half.png";
import EQBrandLogo from "../../assets/img/ecosense-brand-logo-negative-white.png";
import EQLogo from "../../assets/img/EQ200_Logo_wh.png";

import {
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import "../../assets/css/common.css";
import InputBase from "@material-ui/core/InputBase";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("xs")} {
    padding-top: ${(props) => props.theme.spacing(10)}px;
    padding-bottom: ${(props) => props.theme.spacing(10)}px;
    padding-left: ${(props) => props.theme.spacing(15)}px;
    padding-right: ${(props) => props.theme.spacing(15)}px;
  }
`;

function PasscodeRequired() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(1500));

  return (
    <Grid
      container
      direction="row"
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
      }}
    >
      {matches && (
        <Grid
          xs={6}
          item
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: "#3DB5E6",
            width: "100vw",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid item style={{ marginTop: "auto" }}>
            <img
              style={{
                width: "100%",
                height: "80px",
                objectFit: "contain",
              }}
              src={EQLogo}
              alt="ecosense logo"
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h1"
              style={{
                marginTop: 26,
                textAlign: "center",
                color: "white",
                fontFamily: "avenir",
                fontWeight: "500",
                width: "100%",
              }}
            >
              Radon Testing with Ecosense Solution Fast and Accurately
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: "auto" }}>
            <img
              style={{
                width: "100%",
                height: "200px",
                objectFit: "contain",
              }}
              src={EQBrandLogo}
              alt="ecosense brand logo"
            />
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs={matches ? 6 : 12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Wrapper style={{ width: "680px" }}>
          <Helmet title="Reset Password" />
          <div className="s20"></div>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Email Verification Required
          </Typography>
          <div className="s40"></div>
          <Typography component="h2" variant="body1" align="center">
            You have not verified your email address(
            <b>{location.state.email}</b>) yet. Please check your email inbox
            for an email from Ecosense and follow the instructions provided
          </Typography>
          <Formik
            initialValues={{
              email: location.state.email,
              submit: false,
            }}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                const trimmedEmail = location.state.email.trim().toLowerCase();
                dispatch(resendSignUp(trimmedEmail));
                history.push({
                  pathname: "/auth/verify-code",
                  state: { email: trimmedEmail },
                });
              } catch (error) {
                let message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form noValidate onSubmit={handleSubmit}>
                <InputBase disabled inputProps={{ "aria-label": "naked" }} />
                <InputBase disabled inputProps={{ "aria-label": "naked" }} />
                <div className="s20"></div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  classes={{ root: "button", label: "button-label" }}
                  style={{ backgroundColor: theme.palette.primary.main }}
                >
                  Resend
                </Button>
                <div className="s10"></div>
                <Button
                  component={Link}
                  to={location.state.from}
                  fullWidth
                  color="primary"
                  classes={{ label: "link-text" }}
                >
                  Go back to login
                </Button>
              </form>
            )}
          </Formik>
        </Wrapper>
      </Grid>
    </Grid>
  );
}

export default PasscodeRequired;
