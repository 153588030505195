import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import bg from "../../assets/img/bg-half.png";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import { forgotPassword } from "../../models/actions/authActions";
import EQBrandLogo from "../../assets/img/ecosense-brand-logo-negative-white.png";
import EQLogo from "../../assets/img/EQ200_Logo_wh.png";

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import "../../assets/css/common.css";
import { validEmailRegex } from "../../utils/AuthValidation";
import InputBase from "@material-ui/core/InputBase";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("xs")} {
    padding-top: ${(props) => props.theme.spacing(10)}px;
    padding-bottom: ${(props) => props.theme.spacing(10)}px;
    padding-left: ${(props) => props.theme.spacing(15)}px;
    padding-right: ${(props) => props.theme.spacing(15)}px;
  }
`;

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (!validEmailRegex.test(values.email)) {
    errors.email = "Please enter a valid email address.";
  }

  return errors;
};

function ForgotPassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Grid
      container
      direction="row"
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
      }}
    >
      {matches && (
        <Grid
          xs={6}
          item
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: theme.palette.primary.main,
            width: "100vw",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid item style={{ marginTop: "auto" }}>
            <img
              style={{
                width: "100%",
                height: "80px",
                objectFit: "contain",
              }}
              src={EQLogo}
              alt="ecosense logo"
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h1"
              style={{
                marginTop: 26,
                textAlign: "center",
                color: "white",
                fontFamily: "avenir",
                fontWeight: "500",
                width: "100%",
              }}
            >
              Radon Testing with Ecosense Solution Fast and Accurately
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: "auto" }}>
            <img
              style={{
                width: "100%",
                height: "200px",
                objectFit: "contain",
              }}
              src={EQBrandLogo}
              alt="ecosense brand logo"
            />
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs={matches ? 6 : 12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Wrapper style={{ width: "680px" }}>
          <Helmet title="Reset Password" />
          <div>
            <div className="s20"></div>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              Reset your password
            </Typography>
            <div className="s20"></div>
            <Typography component="h2" variant="body1" align="center">
              Please enter the email address you'd like to your
            </Typography>
            <Typography component="h2" variant="body1" align="center">
              password reset information sent to
            </Typography>
          </div>

          <Formik
            initialValues={{
              email: "",
              submit: false,
            }}
            validate={validate}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              const trimmedEmail = values.email.trim().toLowerCase();
              try {
                dispatch(forgotPassword(trimmedEmail));
                history.push({
                  pathname: "/auth/reset-password",
                  state: { email: trimmedEmail },
                });
              } catch (error) {
                let message = error.message || "Something went wrong";

                if (
                  error.message ===
                  "Cannot reset password for the user as there is no registered/verified email or phone_number"
                ) {
                  message = error.message.replace(" or phone_number", "");
                  history.push({
                    pathname: "/auth/passcode-required",
                    state: {
                      from: "/auth/forgot-password",
                      email: trimmedEmail,
                    },
                  });
                } else if (
                  error.message === "Username/client id combination not found."
                ) {
                  message = "Couldn't find your Ecosense account.";
                }

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <>
                    <div className="s10"></div>
                    <Alert mt={2} mb={1} severity="warning">
                      {errors.submit}
                    </Alert>
                  </>
                )}
                <InputBase disabled inputProps={{ "aria-label": "naked" }} />
                <InputBase disabled inputProps={{ "aria-label": "naked" }} />
                <TextField
                  type="email"
                  name="email"
                  label="Email Address"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onChange={handleChange}
                  my={3}
                />
                <InputBase disabled inputProps={{ "aria-label": "naked" }} />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  classes={{ root: "button", label: "button-label" }}
                  style={{ backgroundColor: theme.palette.primary.main }}
                >
                  Reset
                </Button>
              </form>
            )}
          </Formik>
          <div className="s15"></div>
          <div align="center">
            <span className="reset-goback-text">
              Go back to{" "}
              <Link
                className="reset-login-link"
                to="/auth/sign-in"
                style={{ color: theme.palette.primary.main }}
              >
                login
              </Link>
            </span>
          </div>
        </Wrapper>
      </Grid>
    </Grid>
  );
}

export default ForgotPassword;
