import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";

const Resources = () => {
  return (
    <Card mb={6}>
      <CardContent>
        <Grid container direction="column" spacing={6}>
          <Grid item>
            <Typography variant="h5">Support Resources</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" style={{ fontSize: "14px" }}>
              Non-interference agreement
            </Typography>
            <Typography variant="body1" style={{ fontSize: "14px" }}>
              We provide non-interference agreement for your business. Please
              download the form by clicking the button below.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              href="https://cdn.shopify.com/s/files/1/0106/9911/2511/files/EcoQube_Pro_Radon_Test_Authorization_and_Non-Interference_Agreement.docx"
              variant="contained"
              color="primary"
              mt={3}
            >
              Download Non-interference agreement
            </Button>
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <Typography variant="h6" style={{ fontSize: "14px" }}>
              User Guide
            </Typography>
            <Typography variant="body1" style={{ fontSize: "14px" }}>
              Step-by-step for how to use device, mobile app and dashboard.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              href="https://cdn.shopify.com/s/files/1/0106/9911/2511/files/EQ200-UserGuide_V1.0.pdf?v=1692056031"
              variant="contained"
              color="primary"
              mt={3}
            >
              Download User Guide
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Resources;
