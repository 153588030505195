import MaskedInput from "react-text-mask";

export const isNoData = (value) => {
  if (value === undefined || value === null || value === "NA" || value === "N/A" || value === -1) {
    return true
  }
  return false;
}

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const phoneInputMask = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[1-9]/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
};

export const zipcodeInputMask = (props) => {
  const { inputRef, ...other } = props;

  return (
      <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          mask={[
            /[0-9]/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          placeholderChar={"\u2000"}
          showMask
      />
  );
};

export const decodeRx = (msg) => {
  if(msg == null) return null;
  const binString = atob(msg);
  return new TextDecoder().decode(Uint8Array.from(binString, (m) => m.codePointAt(0)));
};

export const encodeTx = (msg) => {
  if(msg == null) return null;
  const binString = Array.from(new TextEncoder().encode(msg), (byte) =>
    String.fromCodePoint(byte),
  ).join("");
  return btoa(binString);
};
