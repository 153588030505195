import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import bg from "../../assets/img/bg-half.png";
import EQBrandLogo from "../../assets/img/ecosense-brand-logo-negative-white.png";
import EQLogo from "../../assets/img/EQ200_Logo_wh.png";
import {
  forgotPassword,
  resetPassword,
} from "../../models/actions/authActions";

import {
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  FormControl as MuiFormControl,
  Grid,
  CircularProgress,
  useTheme,
  useMediaQuery,
  makeStyles,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import "../../assets/css/common.css";
import { validEmailRegex, validPwdRegex } from "../../utils/AuthValidation";

import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import EcoButton from "../../components/EcoButton";

const Alert = styled(MuiAlert)(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;

  ${(props) => props.theme.breakpoints.up("xs")} {
    padding-top: ${(props) => props.theme.spacing(10)}px;
    padding-bottom: ${(props) => props.theme.spacing(10)}px;
    padding-left: ${(props) => props.theme.spacing(15)}px;
    padding-right: ${(props) => props.theme.spacing(15)}px;
  }
`;

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.main,
  },
}));

function ResetPassword() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(1500));

  const [passcode, setPasscode] = useState("");

  const [pwdValues, setPwdValues] = React.useState({
    newPassword: "",
    confirmPassword: "",
    showNewPassword: false,
    showConfirmPassword: false,
  });

  const handlePasscodeChange = (event) => {
    const value = event.target.value;
    setPasscode(value);
  };

  const handlePwdChange = (prop) => (event) => {
    setPwdValues({ ...pwdValues, [prop]: event.target.value });
  };

  const handleClickShowNewPassword = () => {
    setPwdValues({ ...pwdValues, showNewPassword: !pwdValues.showNewPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setPwdValues({
      ...pwdValues,
      showConfirmPassword: !pwdValues.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (!validEmailRegex.test(values.email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (!passcode) {
      errors.passcode = "Required";
    } else if (passcode.length !== 6 || isNaN(passcode)) {
      errors.passcode = "Passcode must be 6 digits";
    }

    if (!pwdValues.newPassword) {
      errors.newPassword = "Required";
    } else if (!validPwdRegex.test(pwdValues.newPassword)) {
      errors.newPassword =
        "It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.";
    }

    if (!pwdValues.confirmPassword) {
      errors.confirmPassword = "Required";
    } else if (!validPwdRegex.test(pwdValues.confirmPassword)) {
      errors.confirmPassword =
        "It must contain 8 or more characters, uppercase letters, lowercase letters and numbers.";
    } else if (
      !errors.newPassword &&
      pwdValues.newPassword !== pwdValues.confirmPassword
    ) {
      errors.confirmPassword =
        "Your new password and confirmation password do not match.";
    }

    return errors;
  };

  return (
    <Grid
      container
      direction="row"
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
      }}
    >
      {matches && (
        <Grid
          xs={6}
          item
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: theme.palette.primary.main,
            width: "100vw",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid item style={{ marginTop: "auto" }}>
            <img
              style={{
                width: "100%",
                height: "80px",
                objectFit: "contain",
              }}
              src={EQLogo}
              alt="ecosense logo"
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h1"
              style={{
                marginTop: 26,
                textAlign: "center",
                color: "white",
                fontFamily: "avenir",
                fontWeight: "500",
                width: "100%",
              }}
            >
              Radon Testing with Ecosense Solution Fast and Accurately
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: "auto" }}>
            <img
              style={{
                width: "100%",
                height: "200px",
                objectFit: "contain",
              }}
              src={EQBrandLogo}
              alt="ecosense brand logo"
            />
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs={matches ? 6 : 12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Wrapper style={{ width: "680px" }}>
          <Helmet title="Reset Password" />
          <div>
            <div className="s20"></div>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              Reset your password
            </Typography>
            <div className="s20"></div>
            <Typography component="h2" variant="body1" align="center">
              We have sent a verification code to
              <br />
              <b>{location.state.email}</b>
              <br />
              Check your spam or junk folder if you don't see it
            </Typography>
          </div>
          <Formik
            initialValues={{
              email: location.state.email,
              submit: false,
            }}
            validate={validate}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                dispatch(
                  resetPassword(
                    values.email.trim().toLowerCase(),
                    passcode,
                    pwdValues.newPassword
                  )
                );
                history.push("/auth/reset-pwd-confirm");
              } catch (error) {
                let message = error.message || "Something went wrong";
                if (error.code === "InvalidParameterException") {
                  message =
                    "Trailing spaces are not allowed in your password \n or one of parameters is invalid";
                }

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <>
                    <div className="s10"></div>
                    <Alert mt={2} mb={1} severity="warning">
                      {errors.submit}
                    </Alert>
                  </>
                )}
                <div className="s30"></div>
                <TextField
                  type="email"
                  name="email"
                  label="Email Address"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  disabled
                  helperText={touched.email && errors.email}
                  onChange={handleChange}
                  my={3}
                />
                <TextField
                  type="text"
                  name="passcode"
                  label="Verification code"
                  value={passcode}
                  error={Boolean(errors.passcode)}
                  fullWidth
                  helperText={errors.passcode}
                  onChange={handlePasscodeChange}
                  my={3}
                />
                <FormControl fullWidth my={2}>
                  <InputLabel
                    htmlFor="standard-new-password"
                    error={errors.newPassword ? true : false}
                  >
                    New Password
                  </InputLabel>
                  <Input
                    id="standard-new-password"
                    autoComplete="new-password"
                    aria-describedby="new-password-helper"
                    type={pwdValues.showNewPassword ? "text" : "password"}
                    value={pwdValues.newPassword}
                    onChange={handlePwdChange("newPassword")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {pwdValues.showNewPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors.newPassword && (
                    <FormHelperText id="new-password-helper" error={true}>
                      {errors.newPassword}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth my={2}>
                  <InputLabel
                    htmlFor="standard-confirm-password"
                    error={errors.confirmPassword ? true : false}
                  >
                    Confirm New Password
                  </InputLabel>
                  <Input
                    id="standard-confirm-password"
                    autoComplete="new-password"
                    aria-describedby="confirm-password-helper"
                    type={pwdValues.showConfirmPassword ? "text" : "password"}
                    value={pwdValues.confirmPassword}
                    onChange={handlePwdChange("confirmPassword")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {pwdValues.showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors.confirmPassword && (
                    <FormHelperText id="confirm-password-helper" error={true}>
                      {errors.confirmPassword}
                    </FormHelperText>
                  )}
                </FormControl>
                <div className="s30"></div>
                <EcoButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  classes={{ root: "button", label: "button-label" }}
                >
                  Reset Your Password
                </EcoButton>
              </form>
            )}
          </Formik>
          <Formik
            initialValues={{
              email: location.state.email,
              submit: false,
            }}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                const trimmedEmail = location.state.email.trim().toLowerCase();
                dispatch(forgotPassword(trimmedEmail));
                history.push({
                  pathname: "/auth/reset-password",
                  state: { email: trimmedEmail },
                });
              } catch (error) {
                let message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="s10"></div>
                <div align="center">
                  <span className="button-left-text">
                    Didn't receive a code?
                  </span>
                  <Button
                    className={classes.button}
                    style={{ paddingTop: "4px" }}
                    type="submit"
                  >
                    Resend
                  </Button>
                  {isSubmitting ? (
                    <div className="resend-spinner">
                      <CircularProgress size={13} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </form>
            )}
          </Formik>
          <div className="s15"></div>
        </Wrapper>
      </Grid>
    </Grid>
  );
}

export default ResetPassword;
