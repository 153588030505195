import * as types from "../../../constants";

const initialState = {
  loading: false,
  clientList: [],
  currentClient: {},
  sort: { order: "desc", orderBy: "clientName" },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SU_CLIENT_SORT:
      return {
        ...state,
        sort: { order: action.payload.order, orderBy: action.payload.orderBy },
      };
    case types.SU_CLIENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SU_CLIENT_LOADING_DISABLE:
      return {
        ...state,
        loading: false,
      };
    case types.SU_RETRIEVE_CLIENT_LIST:
      return {
        ...state,
        clientList: [...action.payload],
        loading: false,
      };
    case types.SU_UPDATE_CLIENT_INFO:
      const updatedClient = Object.assign(state.currentTest, action.payload);

      return {
        ...state,
        currentClient: updatedClient,
        loading: false,
      };
    case types.SU_CURRENT_CLIENT:
      return {
        ...state,
        currentClient: action.payload,
      };
    case types.SU_DELETE_CLIENT:
      let newClientList = [];

      newClientList = state.clientList.filter(
        (client) => client.clientId !== action.payload
      );

      return {
        ...state,
        clientList: [...newClientList],
        loading: false,
      };
    case types.SU_RESET_CLIENT_LIST:
      return {
        ...state,
        currentClient: {},
        clientList: [],
      };
    default:
      return state;
  }
};

export default reducer;
